import { Link } from 'gatsby';
import React, { Component } from 'react';
import PortableText from '@sanity/block-content-to-react';
import serializers from './serializers';
import AddressBlock from './contact-addresses';
import Icon from './icon';
import theme from '../styles/theme.module.scss';
import styles from './contact-intro.module.scss';

function AboutIntro(props) {
  return (
    <div
      className={`${styles.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT6} ${theme.pB3} ${theme.borderBottom}`}>
      <p className={`${theme.huge} ${theme.gds1} ${theme.gde9} ${theme.gmmain} ${theme.mB3}`}>
        {props.topHeadline}
      </p>
      <ul className={`${styles.subPagesLinks} ${theme.gdfull} ${theme.gmfull} ${theme.mB3}`}>
        <li className={` ${theme.borderTop} ${theme.gdcont} ${theme.gmcont} ${theme.h2_founders}`}>
          <Link
            to='/contact/client-inquiries'
            className={`${theme.pT7} ${theme.pB7} ${theme.gdmain} ${theme.fdcont} ${theme.gmmain}  ${theme.fmcont}`}>
            Client Inquiries <Icon className={`${styles.icon}`} symbol='arrowRight' />
          </Link>
        </li>
        <li
          className={`${theme.borderTop} ${theme.gdcont} ${theme.gmcont}  ${
            theme.h2_founders
          } ${!props?.mentorshipFormToggle && theme.borderBottom}`}>
          <Link
            to='/contact/careers'
            className={`${theme.pT7} ${theme.pB7} ${theme.gdmain} ${theme.fdcont} ${theme.gmmain}  ${theme.fmcont}`}>
            Careers <Icon className={`${styles.icon}`} symbol='arrowRight' />
          </Link>
        </li>
        {props?.mentorshipFormToggle && (
          <li
            className={` ${theme.borderTop} ${theme.gdcont} ${theme.gmcont} ${theme.borderBottom} ${theme.h2_founders}`}>
            <Link
              to='/contact/mentorship-program'
              className={`${theme.pT7} ${theme.pB7} ${theme.gdmain} ${theme.fdcont} ${theme.gmmain}  ${theme.fmcont}`}>
              <div>
                Mentorship <span className={`${theme.desktopOnly}`}>Program</span>
              </div>
              <Icon className={`${styles.icon}`} symbol='arrowRight' />
            </Link>
          </li>
        )}
      </ul>
      <AddressBlock {...props} />
    </div>
  );
}

export default AboutIntro;
