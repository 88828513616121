import React, { Component } from "react";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";
import theme from "../styles/theme.module.scss";
import styles from "./contact-intro.module.scss";

function AboutIntro(props) {
  return (
    <div
      className={`${styles.contactInfo} ${theme.gdmain} ${theme.fdcont} ${theme.gmmain}  ${theme.fmcont}`}
    >
      <PortableText
        className={`${theme.portableText} ${theme.h3_tiempos} ${theme.fd4}`}
        renderContainerOnSingleChild={true}
        blocks={props._rawAddressColumn1}
        serializers={serializers}
      />
      <PortableText
        className={`${theme.portableText} ${theme.h3_tiempos} ${theme.fd4}`}
        renderContainerOnSingleChild={true}
        blocks={props._rawAddressColumn2}
        serializers={serializers}
      />
      <PortableText
        className={`${theme.portableText} ${theme.h3_tiempos} ${theme.fd4}`}
        renderContainerOnSingleChild={true}
        blocks={props._rawAddressColumn3}
        serializers={serializers}
      />
    </div>
  );
}

export default AboutIntro;
