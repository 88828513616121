import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../components/container';
import GraphQLErrorList from '../../components/graphql-error-list';
import Layout from '../../containers/layout';
import SEO from '../../components/seo';
import Intro from '../../components/contact-intro';

export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
      mentorshipFormToggle
    }
    contact: sanityContact {
      ...SanityContactFragment
    }
  }
`;

const ContactPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const contact = (data || {}).contact;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <h1 hidden>Contact</h1>
        <Intro {...contact} {...site} />
      </Container>
    </Layout>
  );
};

export default ContactPage;
